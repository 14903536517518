import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3177738"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["valign"]
const _hoisted_2 = { class: "label" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass([_ctx.price ? 'item' : 'section', _ctx.standOut && 'standout']),
    valign: _ctx.price ? 'top' : 'bottom'
  }, [
    _createElementVNode("td", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.price)
      ? (_openBlock(), _createElementBlock("td", {
          key: 0,
          innerHTML: _ctx.priceFormatted,
          class: "price"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}