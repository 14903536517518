
import { defineComponent, computed, PropType } from 'vue'

export default defineComponent({
    name: 'mainPage',
    props: {
        price: { type: Object as PropType<number | number[]>, required: false },
        priceRange: { type: Boolean, default: false },
        standOut: { type: Boolean, default: false },
    },
    setup(props) {
        function formatPrice(price: number): string {
            const arr = price.toString().split('.')
            const decimals = arr[1] ? `<span class="delim"></span><span class="digits">${arr[1]}</span>` : ''
            return `${arr[0]}${decimals}`
        }
        function formatPrices(prices: number | number[], priceRange = false): string {
            if (typeof prices === 'number') {
                prices = [prices]
            }
            const delimiter = priceRange ? ' do ' : ' / '

            return `${prices
                .map((price, i, _prices) => (i === _prices.length - 1 ? formatPrice(price) : price))
                .join(delimiter)} <span class="eur"></span>`
        }

        return {
            priceFormatted: computed(() => (props.price ? formatPrices(props.price, props.priceRange) : '')),
        }
    },
})
